import { useAuth } from "./AuthContext";
import { Navigate, Route, Routes } from "react-router-dom";

import ChooseLeague from "./components/ChooseLeague";
import CreateTeamPage from "./components/CreateTeamPage";
import ChangeTeamPage from "./components/ChangeTeamPage";
import Profil from "./components/Profil";
import Ranking from "./components/ProfilView/Ranking";
import UserTeam from "./components/ProfilView/UserTeam";
import SetRaceRanking from "./components/SetRaceRanking";

import createTeamConfig from "./config/createTeamConfig.json";

const isAfterDeadline = () => {
    const deadline = new Date(createTeamConfig.closeDate);
    const now = new Date();
    return now > deadline;
};

const isTodayChangeableDate = () => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
  
    const changeableDate1 = new Date(createTeamConfig.changeableDate1).toISOString().split('T')[0];
    const changeableDate2 = new Date(createTeamConfig.changeableDate2).toISOString().split('T')[0];
    const changeableDate3 = new Date(createTeamConfig.changeableDate3).toISOString().split('T')[0];
    const changeableDate4 = new Date(createTeamConfig.changeableDate4).toISOString().split('T')[0];
    const changeableDate5 = new Date(createTeamConfig.changeableDate5).toISOString().split('T')[0];
    const changeableDate6 = new Date(createTeamConfig.changeableDate6).toISOString().split('T')[0];
    const changeableDate7 = new Date(createTeamConfig.changeableDate7).toISOString().split('T')[0];
    const changeableDate8 = new Date(createTeamConfig.changeableDate8).toISOString().split('T')[0];
    const changeableDate9 = new Date(createTeamConfig.changeableDate9).toISOString().split('T')[0];
  
    return formattedToday === changeableDate1 || formattedToday === changeableDate2 || formattedToday === changeableDate3 || formattedToday === changeableDate4 || formattedToday === changeableDate5 || formattedToday === changeableDate6 || formattedToday === changeableDate7 || formattedToday === changeableDate8 || formattedToday === changeableDate9;
};

const ProtectedRoutes = () => {
    const { user, isLoading, accountType } = useAuth();

    if (isLoading) {
        return <div>Chargement...</div>;
    }

    const chooseLeagueElement = !user ? <Navigate to="/login" /> : isAfterDeadline() ? <Navigate to="/" /> : <ChooseLeague />;
    const createTeamElement = !user ? <Navigate to="/login" /> : isAfterDeadline() ? <Navigate to="/" /> : <CreateTeamPage />;
    const changeTeamElement = !user ? <Navigate to="/login" /> : isTodayChangeableDate() ? <ChangeTeamPage /> : <Navigate to="/" />;

    return (
        <Routes>
            <Route path="/profil" element={user ? <Profil /> : <Navigate to="/login" />} />
            <Route path="/choose-league" element={chooseLeagueElement} />
            <Route path="/create-team/:teamId" element={createTeamElement} />
            <Route path="/change-team/:teamId" element={changeTeamElement} />
            <Route path="/userTeam/:userId" element={user ? <UserTeam /> : <Navigate to="/login" />} />
            <Route path="/ranking/" element={user ? <Ranking /> : <Navigate to="/login" />} />
            <Route path="/set-race-ranking" element={accountType === 'admin' ? <SetRaceRanking /> : <Navigate to="/" />} />
        </Routes>
    );
};

export default ProtectedRoutes;
