import React, { useEffect, useState } from "react";

import "../styles/SelectNewCyclists.css";

const SelectCyclists = ({ selectedCyclists, numberPlayerSelected, setNumberPlayerSelected, budget, setBudget, selectedNewCyclists, setSelectedNewCyclists, cyclists, prevStep, nextStep }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [cyclistsList, setCyclistsList] = useState([]);
    const [newCyclistsSelectedNumber, setNewCyclistsSelectedNumber] = useState(selectedNewCyclists.length);
    const [selectedSortValue, setSelectedSortValue] = useState("value");

    useEffect(() => {
        const selectedCyclistsIds = selectedCyclists.map(cyclist => cyclist.cyclistId);
        
        const filteredCyclists = cyclists.filter(cyclist => !selectedCyclistsIds.includes(cyclist.cyclistId));
    
        setCyclistsList(filteredCyclists);
      }, [cyclists, selectedCyclists]);

    const handleSelectCyclist = cyclist => {
        if (selectedNewCyclists.some(selected => selected.cyclistId === cyclist.cyclistId)) {
            setSelectedNewCyclists(selectedNewCyclists.filter(selected => selected.cyclistId !== cyclist.cyclistId));
            setBudget(budget + cyclist.finalValue);
            setNumberPlayerSelected(numberPlayerSelected + 1);
            setNewCyclistsSelectedNumber(newCyclistsSelectedNumber - 1);

        } else if (budget >= cyclist.finalValue) {
            setSelectedNewCyclists([...selectedNewCyclists, cyclist]);
            setBudget(budget - cyclist.finalValue);
            setNumberPlayerSelected(numberPlayerSelected - 1);
            setNewCyclistsSelectedNumber(newCyclistsSelectedNumber + 1);
        }
    };

    const isCyclistSelected = cyclistId => {
        const isSelected = selectedNewCyclists.some(selected => selected.cyclistId === cyclistId);
        return isSelected;
    };

    const isButtonDisabled = numberPlayerSelected !== 0;

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    
        if (newSearchTerm) {
            const filteredCyclists = cyclists.filter(cyclist =>
                cyclist.name.toLowerCase().startsWith(newSearchTerm.toLowerCase())
            );
            sortCyclists(selectedSortValue, filteredCyclists);
        } else {
            sortCyclists(selectedSortValue, cyclists);
        }
    };

    const handleSortChange = (event) => {
        const sortBy = event.target.value;
        setSelectedSortValue(sortBy);

        sortCyclists(sortBy, cyclistsList);
    };

    const sortCyclists = (sortBy, sortedCyclists) => {
        switch (sortBy) {
            case "value":
                sortedCyclists.sort((a, b) => b.finalValue - a.finalValue);
                break;
            case "nation":
                sortedCyclists.sort((a, b) => a.nationality.localeCompare(b.nationality));
                break;
            case "team":
                sortedCyclists.sort((a, b) => a.team.localeCompare(b.team));
                break;
            default:
        }
        setCyclistsList(sortedCyclists);
    };

    return (
        <main className="select-new-cyclists">
            <div className="select-new-cyclists-shadow-container">
                <div className="select-new-cyclists-title">
                    <h1>JOUEURS INTERCHANGEABLE 2/3(ATTENTION : VOUS NE POURREZ ÉCHANGER VOS JOUEURS QU'UNE SEULE FOIS AUJOURD'HUI)</h1>
                    <div className="select-new-cyclists-shadow-mask-right"></div>
                    <div className="select-new-cyclists-shadow-mask-bottom"></div>
                </div>
            </div>
            <div className="select-new-cyclists-rules">
                <p >Sélectionnez les nouveaux joueurs de votre équipe</p>
            </div>

            <div className="select-new-cyclists-pannel-view">
                <div className="select-new-cyclists-choice">
                    <div className="select-new-cyclists-header-pannel">
                        <div className="select-new-cyclists-header-pannel-content">
                            <p>Nombre de joueurs sélectionnés : <span>{newCyclistsSelectedNumber}</span></p>
                        </div>                
                        <div className="select-new-cyclists-header-pannel-content">
                            <p>Budget restant : <strong>{budget}M</strong></p>
                        </div>
                    </div>
                    <div className="select-new-cyclists-controls">
                        <div className="select-new-cyclists-sort-control">
                            <label>Trier par</label>
                            <select onChange={handleSortChange}>
                                <option value="value">Valeur</option>
                                <option value="nation">Nation</option>
                                <option value="team">Équipe</option>
                            </select>
                        </div>
                        <div className="select-new-cyclists-search-control">
                            <img src={`${process.env.PUBLIC_URL}/icons/loupe.png`} alt="Icone d'une loupe" width="22" height="22" className="select-new-cyclists-search-icon" />
                            <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Rechercher par nom" />
                        </div>
                    </div>
                    <div className="select-new-cyclists-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>COUREUR</th>
                                    <th>ÉQUIPE</th>
                                    <th>NATION</th>
                                    <th>VALEUR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cyclistsList.map((cyclist) => (
                                    <tr key={cyclist.cyclistId} onClick={() => handleSelectCyclist(cyclist)} className={isCyclistSelected(cyclist.cyclistId) ? "select-new-cyclists-table-selected-row" : ""}>
                                        <td>
                                            <div className="select-new-cyclists-name-info">
                                                <img 
                                                    className="select-new-cyclists-flag-icon"
                                                    src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality.replace(/ /g, '_').toLowerCase()}.png`} 
                                                    alt={`Drapeau de ${cyclist.nationality}`} 
                                                    width="20" 
                                                    height="20"
                                                />
                                                <span>{cyclist.name}</span>
                                            </div>
                                        </td>
                                        <td>{cyclist.team}</td>
                                        <td>{cyclist.nationality}</td>
                                        <td>{cyclist.finalValue}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>  
                
                <div className="select-new-cyclists-choice-view">
                    <div className="select-new-cyclists-change-step">
                        <button onClick={prevStep}>PRÉCÉDENT</button>
                        <button onClick={nextStep} disabled={isButtonDisabled}>VALIDER</button>
                    </div>


                <div className="select-new-cyclists-line-container">
                    {selectedNewCyclists.map((cyclist) => (
                        <div className="select-new-cyclists-selected-cell" key={cyclist.cyclistId}>
                            <img src={`${process.env.PUBLIC_URL}/icons/cancel.png`} className="select-new-cyclists-cancel-icon" alt="Icone de suppression" width="17" height="17" onClick={() => handleSelectCyclist(cyclist)} />
                            <div className="select-new-cyclists-selected-info">                                        
                                <h4>
                                    <img 
                                        className="select-new-cyclists-flag-icon"
                                        src={`${process.env.PUBLIC_URL}/flags/${cyclist.nationality.replace(/ /g, '_').toLowerCase()}.png`} 
                                        alt={`Drapeau de ${cyclist.nationality}`} 
                                        width="20" 
                                        height="20"
                                    />
                                    {cyclist.name}</h4>
                                <p>{cyclist.team}</p>
                                <p>Valeur : <strong>{cyclist.finalValue}</strong></p>
                                <div style={{ width: "100%", height: "15px", backgroundColor: cyclist.teamColor, marginTop: "35px", marginLeft: "auto", marginRight: "auto" }}></div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>              
            </div>            
        </main>
    );        
}

export default SelectCyclists;